import type { EventKey } from 'cadenza/utils/event-util';
import { unByKey } from 'cadenza/utils/event-util';

import type { EventsKey } from 'ol/events';

interface Disconnectable {
  disconnect(): void;
}

/**
 * A teardown function, an event key, an object with a "disconnect" method,
 * a timeout or interval, or - for convenience - `undefined`
 */
export type Teardown = (() => void) | EventKey | EventsKey | Disconnectable | number | undefined;

/**
 * Convenient teardown util to ...
 * - Unsubscribe from Redux state changes.
 * - Unsubscribe from Cadenza API events.
 * - Remove DOM and OpenLayers event listeners.
 * - Clear timeouts and intervals.
 * - Call the `.disconnect()` method on an object like a `ResizeObserver`.
 * - Run arbitrary cleanup functions.
 *
 * _Note:_ It is important to clean all these things up to avoid memory leaks. However, it is _not_ necessary
 * to remove DOM listeners on `this` (or descendants of `this`), because they're automatically cleaned up
 * when `this` is garbage-collected.
 *
 * @example
 *   class MyComponent extends HTMLElement {
 *     #teardowns: Teardown[] = [];
 *
 *     ...
 *
 *     connectedCallback () {
 *       this.#teardowns.push(...);
 *     }
 *
 *     disconnectedCallback () {
 *       this.#teardowns = tearDown(this.#teardowns);
 *     }
 *   }
 * @param teardowns - The things to tear down
 * @return An empty array that can be used to re-assign a class field or variable with the teardowns.
 */
export function tearDown(teardowns: Teardown[]): Teardown[];
/**
 * Convenient teardown util to ...
 * - Unsubscribe from Redux state changes.
 * - Unsubscribe from Cadenza API events.
 * - Remove DOM and OpenLayers event listeners.
 * - Call the `.disconnect()` method on an object like a `ResizeObserver`.
 * - Clear timeouts and intervals.
 *
 * _Note:_ It is important to clean all these things up to avoid memory leaks. However, it is _not_ necessary
 * to remove DOM listeners on `this` (or descendants of `this`), because they're automatically cleaned up
 * when `this` is garbage-collected.
 *
 * @example
 *   class MyComponent extends HTMLElement {
 *     #teardown: Teardown;
 *
 *     ...
 *
 *     connectedCallback () {
 *       this.#teardown = ...;
 *     }
 *
 *     disconnectedCallback () {
 *       this.#teardown = tearDown(this.#teardown);
 *     }
 *   }
 * @param teardown - The thing to tear down
 * @return An undefined value that can be used to re-assign a class field or variable with the teardown.
 */
export function tearDown(teardown: Teardown): undefined;
export function tearDown(teardowns: Teardown[] | Teardown): Teardown[] | undefined {
  if (Array.isArray(teardowns)) {
    teardowns.forEach(tearDownOne);
    return [];
  } else {
    tearDownOne(teardowns);
  }
}

function tearDownOne(teardown: Teardown) {
  if (teardown != null) {
    if (typeof teardown === 'function') {
      // Redux, cadenza/integration/post-message#subscribeToEvent
      teardown();
    } else if (typeof teardown === 'number') {
      // Note: The pool of IDs used by setTimeout() and setInterval() are shared,
      // which means you can use clearTimeout() and clearInterval() interchangeably.
      clearTimeout(teardown);
    } else if ('disconnect' in teardown) {
      teardown.disconnect();
    } else if ('types' in teardown) {
      // cadenza/utils/event-util#on
      unByKey(teardown);
    } else if ('type' in teardown) {
      // OpenLayers
      const { target, type, listener } = teardown;
      target.removeEventListener(type, listener);
    }
  }
}
